<template>
  <div class="container">
      <div class="main-img-box">
        <img :src="main_img_url" />
      </div>
      <div class="switch-img-box">
        <div class="previous" @click="previous()">
          <div></div>
        </div>
        <div class="thumbnail-box" >
          <ul :style="{left: ulLeft + 'px'}">
              <li v-for="(item,index) in imgList" :key="index" @mouseenter="setMainImg(item,index)">
                <img :src="item" object-fit="cover" :class="{'select-on':index == select_index}"/>
              </li>
            </ul>
        </div>
        <div class="next" @click="next()">
          <div></div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name:"ImgBanner",
  components:{},
  props:{
    imgList:Array
  },
  data(){
    return{
      main_img_url:'',
      total_px:0,
      ulLeft:0,
      select_index:0
    }
  },
  mounted(){
    if(this.imgList){
      this.main_img_url = this.imgList[0]
    }
  },
  methods:{
    setMainImg(img_url,index){
      this.select_index = index
      this.main_img_url = img_url
    },
    next(){
      if(Math.abs(this.ulLeft) + (70 + 13) * 5 >= this.total_px){
        return
      }
      this.ulLeft -= (70 + 13) * 5
    },
    previous(){
      if(this.ulLeft >= 0){
        return
      }
      this.ulLeft += (70 + 13) * 5
    }
  },
  watch:{
    imgList(newv){
        if(newv){
          this.main_img_url = newv[0]
          let count = newv.length
          this.total_px =  ((70 + 13) * count)
        }
    },

  }

}
</script>

<style scoped lang="scss">
.container{
  height: 100%;
  min-height: 640px;
  display: flex;
  flex-direction: column;
}
.main-img-box{
  flex: 1;
  background-color: antiquewhite;
  max-height: 500px;
  img{
    height: 100%;
    // max-height: 500px;
    width: 100%;
  }
}
.switch-img-box{
    margin-top: 2px;
    height: 70px;
    display: flex;
    justify-content: space-between;
    .previous{
      overflow: hidden;
      width: 30px;
      margin-right: 5px;
      div{
        width: 50px;
        height: 50px;
        border-width: 6px;
        border-color: #6c757d;
        border-style: solid;
        transform: matrix(0.71, 0.71, -0.71, 0.71, 15, 10);
      }
    }
    .next{
      margin-left: 5px;
      width: 30px;
      overflow: hidden;
      div{
        width: 50px;
        height: 50px;
        border-width: 6px;
        border-color: #6c757d;
        border-style: solid;
        transform: matrix(0.71, 0.71, -0.71, 0.71, -35, 10);
      }
      
    }
    .thumbnail-box{
      overflow: hidden;
      flex: 1;
      ul{
          transition-duration:1s;
          position: relative;
          overflow: hidden;
          padding-left: 0 !important;
          margin-bottom: 0 !important;
          width: 20000px;
          display: block;
          li{
            margin-left: 6.5px !important;
            margin-right: 6.5px !important;
            width: 70px;
            list-style: none;
            display: inline-block;
            margin-left: 15px;
            img{
              height: 100%;
              width: 70px;
              height: 68px;
            }
          }
        }
    }
    
}

.select-on{
  border: 2px red solid;
}
</style>